import { AdminButton } from './AdminButton'

type Props = {
  onCancel: () => void
  submitText?: string
  loading?: boolean
}

export const AdminFormActions = ({
  onCancel,
  submitText = 'Save',
  loading,
}: Props) => {
  return (
    <div className="flex justify-end gap-2">
      {!!onCancel && (
        <AdminButton className="btn-error" onClick={onCancel} loading={loading}>
          Cancel
        </AdminButton>
      )}
      <AdminButton className="btn-primary" type="submit" loading={loading}>
        {submitText}
      </AdminButton>
    </div>
  )
}
