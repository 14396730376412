import {
  vISODate,
  vObject,
  vOptional,
  vOutput,
  vPipe,
  vString,
} from '../validation'
import { ChargeName } from './charge'
import { ShipmentDirection, Workflow } from './common'
import { LoadDocumentDTO } from './load-document'
import { LoadNoteDTO } from './load-note'
import { QuoteDTO } from './quote'

export const auditStatuses = [
  'outstanding',
  'accepted',
  'disputed',
  'archived',
] as const
export type AuditStatus = (typeof auditStatuses)[number]

export interface InvoiceLineItem {
  description: string
  amount: number
  type: ChargeName
}

export interface Audit {
  createdDate: Date
  accountId: string
  loadId: string
  quoteRequestId: string
  pickupDate: Date
  direction: ShipmentDirection
  assetCarrierName?: string
  providerName?: string
  bolNum?: string
  trackingNumber?: string
  pickupConfirmationNumber?: string
  quote: QuoteDTO
  invoices: LoadDocumentDTO[]
  invoiceNumber?: string
  invoiceAmount?: number
  invoiceDate?: Date
  disputedAmount?: number
  payments?: InvoicePaymentDTO[]
  refNums: Array<{ type: string; value: string }>
  workflow: Workflow
  notes?: LoadNoteDTO[]
  statuses: AuditStatus[]
  parseMethod?: InvoiceParseMethod
  sentDisputeEmail: boolean
  pickupDateActual?: Date
  deliveryDate?: Date
  accountLocations: string[]
  tags: string[]
  source?: InvoiceSource
  lineItems: InvoiceLineItem[]
}
interface AddedBy {
  userId?: string
  type: 'system' | 'shipper'
}

interface InvoicePaymentDTO {
  amount: number
  remittedDate?: Date
  remitted?: boolean
  createdDate: Date
  addedBy?: AddedBy
}
export type AutoInvoiceStatus = 'ok' | 'error' | 'needs-review'
export type InvoiceParseMethod = 'ai' | 'api'
export type InvoiceSource = 'document' | 'api' | 'manual'

export const updateAuditSchema = vObject({
  invoiceNumber: vOptional(vString()),
  invoiceDate: vOptional(vPipe(vString(), vISODate())),
})
export type UpdateAuditDto = vOutput<typeof updateAuditSchema>
