import {
  type UseInfiniteQueryResult,
  type UseQueryResult,
} from '@tanstack/react-query'
import clsx from 'clsx'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import orderBy from 'lodash/orderBy'
import { type ReactNode, useEffect, useState } from 'react'
import { type FieldPath } from 'react-hook-form'

import { Icon, LoadMore } from '@fv/client-components'
import { flattenPages, type Page } from '@fv/client-core'
import { type HintedString } from '@fv/models/core'

import { type SortDirection } from '../../features/dispatches/types'
import { AdminCheckbox } from './AdminCheckbox'
import { AdminLoading } from './AdminLoading'

export type SortFn<T> = (data: T[], dir: SortDirection) => T[]
export type ColumnDef<T extends object> = {
  hidden?: boolean
  checkbox?: {
    enabled: boolean
    checked?: boolean
    onToggle?: (checked: boolean) => void
  }
  key: HintedString<FieldPath<T>>
  label?: string | JSX.Element
  render?: (data: T) => ReactNode
  sort?: SortFn<T>
  sortable?: boolean
  defaultValue?: any
  className?: string
  pin?: boolean
}

export type SortDef<T extends object = object> = {
  key: HintedString<FieldPath<T>>
  dir: SortDirection
}
export type AdminTableProps<T extends object> = {
  columns: Array<ColumnDef<T>>
  data?: T[]
  rowClassName?: (data: T) => string | undefined | null | object
  initialState?: {
    sort: SortDef<T>
  }
  rowKey: (data: T) => string
  onRowSelect?: (data: T) => void
  onSortChange?: (sort: SortDef<T>) => void
  disableSort?: boolean
  emptyContent?: ReactNode
  defaultSort?: string
  defaultSortDir?: SortDirection
  className?: string
  defaultValue?: any
}

type ColHeaderProps<T extends object> = {
  col: ColumnDef<T>
  sort: SortDef<T>
  onSort: (sort: SortDef<T>) => void
  disableSort?: boolean
}
const ColumnHeader = <T extends object>({
  col,
  sort,
  onSort,
  disableSort,
}: ColHeaderProps<T>) => {
  if (col.checkbox?.enabled) {
    return (
      <AdminCheckbox
        id={`${col.key}_all`}
        checked={col.checkbox.checked}
        onChange={e => col.checkbox?.onToggle?.(e.target.checked)}
      />
    )
  }

  return (
    <button
      disabled={col.sortable === false}
      className={clsx({
        'cursor-default': disableSort || col.sortable === false,
      })}
      onClick={() => {
        if (disableSort || col.sortable === false) return
        onSort({
          key: col.key,
          dir: sort.key === col.key && sort.dir === 'asc' ? 'desc' : 'asc',
        })
      }}
    >
      {col.label ?? col.key.toString()}
      {!disableSort && col.label && sort.key === col.key && (
        <Icon
          className="ml-3"
          icon={sort.dir === 'desc' ? 'arrow-down' : 'arrow-up'}
        />
      )}
    </button>
  )
}

export const AdminTable = <T extends object>({
  className,
  columns,
  data,
  disableSort,
  emptyContent,
  initialState,
  onRowSelect,
  onSortChange,
  rowClassName,
  rowKey,
  defaultSort,
  defaultSortDir,
}: AdminTableProps<T>) => {
  const sortableCols = columns.filter(c => c.sortable !== false)
  const [sort, setSort] = useState<SortDef<T>>(
    initialState?.sort ?? {
      key: defaultSort ?? sortableCols[0].key,
      dir: defaultSortDir ?? 'asc',
    },
  )
  useEffect(() => {
    if (onSortChange) {
      onSortChange(sort)
    }
  }, [sort, onSortChange])
  if (!data?.length && emptyContent) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{emptyContent}</>
  }

  let sortedData = data ?? []
  if (!onSortChange && !disableSort) {
    const colDef = columns.find(c => c.key === sort.key && c.sortable !== false)
    if (colDef?.sort) {
      sortedData = colDef.sort(data ?? [], sort.dir)
    } else if (
      colDef &&
      (get(data?.[0], sort.key) || !isNil(colDef.defaultValue))
    ) {
      sortedData = orderBy(
        data,
        d => {
          const propVal = get(d, sort.key)
          return typeof propVal === 'string'
            ? propVal.toLowerCase()
            : (propVal ?? colDef.defaultValue)
        },
        [sort.dir],
      )
    }
  }

  return (
    <table className={clsx('table-zebra-zebra table', className)}>
      <thead>
        <tr>
          {columns.map(c => {
            const CellTag = c.pin ? `th` : 'td'

            return (
              <CellTag
                key={`th.${c.key}`}
                className={clsx({ hidden: c.hidden }, c.className)}
              >
                <ColumnHeader
                  col={c}
                  sort={sort}
                  onSort={setSort}
                  disableSort={disableSort}
                />
              </CellTag>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {sortedData.map(d => {
          const key = rowKey(d)
          return (
            <tr
              className={clsx(rowClassName?.(d))}
              onClick={() => onRowSelect?.(d)}
              key={rowKey(d)}
            >
              {columns.map(col => {
                const CellTag = col.pin ? `th` : 'td'
                return (
                  <CellTag
                    key={`${key}.${col.key}`}
                    className={clsx({ hidden: col.hidden }, col.className)}
                  >
                    {col.render?.(d) ?? (get(d, col.key) || col.defaultValue)}
                  </CellTag>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

type AdminQueryTableProps<T extends object> = Omit<
  AdminTableProps<T>,
  'data'
> & {
  query: UseQueryResult<T[]>
}

export const AdminQueryTable = <T extends object>({
  query,
  ...props
}: AdminQueryTableProps<T>) => {
  const data = query.data ?? []
  const emptyContent =
    query.isFetched && !query.data?.length ? props.emptyContent : undefined
  return (
    <div className="relative">
      {(query.isFetched || query.isFetching) && (
        <AdminTable data={data} emptyContent={emptyContent} {...props} />
      )}
      {query.isFetching && (
        <div className="bg-neutral/90 text-neutral-content absolute left-0 top-0 flex h-full min-h-[200px] w-full justify-center pt-10 text-lg ">
          <div>
            <AdminLoading />
          </div>
        </div>
      )}
    </div>
  )
}

type AdminInfiniteQueryTableProps<TModel extends object> = Omit<
  AdminQueryTableProps<TModel>,
  'query'
> & {
  query: UseInfiniteQueryResult<Page<TModel>>
}

export const AdminInfiniteQueryTable = <TModel extends object>({
  query,
  ...props
}: AdminInfiniteQueryTableProps<TModel>) => {
  const data = flattenPages(query.data?.pages)
  const emptyContent =
    query.isFetched && !data?.length ? props.emptyContent : undefined
  return (
    <div className="relative">
      {(query.isFetched || query.isFetching) && (
        <>
          <AdminTable data={data} emptyContent={emptyContent} {...props} />
          <LoadMore
            fetchNextPage={query.fetchNextPage}
            hasNextPage={query.hasNextPage}
            isLoading={query.isFetchingNextPage}
          />
        </>
      )}
      {query.isInitialLoading && (
        <div className="bg-neutral/90 text-neutral-content absolute left-0 top-0 flex h-full min-h-[200px] w-full justify-center pt-10 text-lg ">
          <div>
            <AdminLoading />
          </div>
        </div>
      )}
    </div>
  )
}
