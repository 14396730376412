type ValidationErrors = {
  propertyName: string
  message: string
  allowedValues?: string[]
  providedValue?: unknown
}

export type ApiError = {
  message?: string
  errors?: ValidationErrors[]
}

export const isApiError = (error: any): error is ApiError => {
  return !!error.message || !!error.errors?.length
}

export type ErrorPathAliases = Record<string, string>

// TODO: check capitalization, punctuation
export const getApiErrorText = (
  error: unknown,
  pathAliases?: ErrorPathAliases,
) => {
  const apiError = error as ApiError
  if (!apiError) return

  let message = ''

  if (apiError.errors) {
    message += apiError.errors
      .map(e => buildErrorMessage(e, pathAliases))
      .join(', ')
  } else if (apiError.message) {
    message += apiError.message
  }
  return message
}

function buildErrorMessage(
  error: ValidationErrors,
  pathAliases?: ErrorPathAliases,
) {
  const lastPeriod = error.propertyName?.lastIndexOf('.')
  let prop: string | undefined = undefined
  let path: string = error.propertyName
  if (lastPeriod > -1) {
    prop = error.propertyName.substring(lastPeriod + 1)
    path = error.propertyName.substring(0, lastPeriod)
  }
  const pathAlias = pathAliases?.[path]
  const fullPath = [pathAlias ?? path, prop]
    .filter(v => !!v)
    .join(pathAlias ? ' ' : '.')
  return `${fullPath} ${error.message}`.trim()
}
