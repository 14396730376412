import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { showApiError } from '@fv/client-core'
import { type ChangeUserCarrierDTO } from '@fv/models'
import { adminFetch } from '@/utils'

async function changeCarrier({
  carrierId,
  userId,
}: ChangeUserCarrierDTO & { userId: string }) {
  return adminFetch(`/users/${userId}/carrier`, {
    method: 'PUT',
    body: { carrierId },
  })
}

export function useChangeCarrier(userId: string) {
  return useMutation(
    (carrierId: string) => changeCarrier({ userId, carrierId }),
    {
      onError: e => {
        showApiError('Error changing carrier', e)
      },
      onSuccess: () => {
        toast.success('Carrier changed')
      },
    },
  )
}

async function verifyUser(userId: string) {
  return adminFetch(`/users/${userId}/verify`, {
    method: 'POST',
  })
}

export function useVerifyUser() {
  return useMutation(verifyUser, {
    onError: e => {
      showApiError('Error verifying user', e)
    },
    onSuccess: () => {
      toast.success('User verified')
    },
  })
}
