import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import { FvDate, Icon } from '@fv/client-components'
import { type AdminCarrierUser } from '@fv/models'
import { type DTO } from '@fv/models/core'
import { AdminSliderPanel } from '@/components/AdminSliderPanel'
import { AdminButton } from '@/components/shared/AdminButton'
import { AdminLink } from '@/components/shared/AdminLink'
import { AdminTable } from '@/components/shared/AdminTable'
import { usersQueryKeys } from '@/hooks/useFetchUsers'
import { useRevokeSession } from '@/hooks/useRevokeSession'

import { NO_EXPORT_ATTR } from '../reports/helpers'

type Props = {
  user: DTO<AdminCarrierUser>
}

export const SessionSliderButton = ({ user }: Props) => {
  const revokeSession = useRevokeSession()
  const queryClient = useQueryClient()
  const [isOpen, setIsOpen] = useState(false)

  const handleSessionRevoke = async (id: string) => {
    if (window.confirm('Are you sure you want to revoke this session?')) {
      await revokeSession.mutateAsync(id)
      queryClient.invalidateQueries(usersQueryKeys.all)
    }
  }

  return (
    <>
      <AdminLink
        className="btn-xs p-0"
        onClick={() => setIsOpen(true)}
        {...{ [NO_EXPORT_ATTR]: true }}
      >
        View sessions
      </AdminLink>
      <AdminSliderPanel
        isOpen={isOpen}
        closePanel={() => setIsOpen(false)}
        title={`${user?.email} sessions`}
      >
        {!!user && (
          <AdminTable
            emptyContent={
              <div className="text-center mt-4">
                <Icon icon="ban" /> No sessions for this user
              </div>
            }
            rowKey={s => s._id}
            columns={[
              {
                key: '_id',
                label: 'Session Id',
                render: s => s._id,
              },
              {
                key: 'lastUsed',
                render: s => (
                  <FvDate val={s.lastUsed} format="YYYY-MM-DD h:mm A" utc />
                ),
              },
              {
                key: 'revoke',
                label: '',
                render: s => (
                  <AdminButton
                    onClick={() => handleSessionRevoke(s._id)}
                    className="btn-xs btn-error"
                    icon="trash"
                  />
                ),
              },
            ]}
            data={user?.sessions ?? []}
          />
        )}
      </AdminSliderPanel>
    </>
  )
}
