import clsx from 'clsx'
import { type ForwardedRef, forwardRef } from 'react'
import { type NavLinkProps } from 'react-router-dom'

import {
  FvLinkButton,
  type FvLinkButtonProps,
  FvNavLink,
} from '@fv/client-components'

type Props = FvLinkButtonProps
export const AdminLink = forwardRef(
  ({ className, ...props }: Props, ref: ForwardedRef<HTMLAnchorElement>) => (
    <FvLinkButton
      ref={ref}
      className={clsx('link text-primary', className)}
      iconClass={clsx('mr-2', props.iconClass)}
      {...props}
    >
      {props.children}
    </FvLinkButton>
  ),
)

export const AdminNavLink = forwardRef(
  (
    { className, ...props }: NavLinkProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => {
    return (
      <FvNavLink
        ref={ref}
        className={state =>
          clsx(
            'link',
            typeof className === 'function' ? className(state) : className,
            {
              'text-primary': !(
                typeof className === 'function' ? className(state) : className
              )?.includes('text-'),
            },
          )
        }
        {...props}
      >
        {props.children}
      </FvNavLink>
    )
  },
)
