import clsx from 'clsx'
import Select, { type Props as SelectProps } from 'react-select'

type Props<TModel> = SelectProps<TModel, false>

export function AdminTypeaheadField<T>({ name, ...props }: Props<T>) {
  return (
    <div className="w-full" data-cy={name}>
      <Select
        {...props}
        unstyled
        name={name}
        classNames={{
          container: ({ isDisabled, isFocused }) =>
            clsx({
              'outline outline-2 outline-offset-2 outline-secondary': isFocused,
            }),
          dropdownIndicator: () => '!hidden',
          control: ({ isDisabled, isFocused }) => clsx('input input-bordered'),
          menuList: () => 'border shadow-md mt-1 bg-white',
          option: ({ isFocused }) =>
            'menu-item border-b p-2 hover:bg-secondary hover:bg-opacity-20',
          loadingMessage: () =>
            'menu-item border-b p-2 hover:bg-secondary hover:bg-opacity-20 ',
          noOptionsMessage: () =>
            'menu-item border-b p-2 hover:bg-secondary hover:bg-opacity-20 ',
          placeholder: () => 'text-gray-500',
        }}
      />
    </div>
  )
}
