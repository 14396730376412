import {
  createDefault,
  vArray,
  vBoolean,
  vEmail,
  vFallback,
  vFallbackObj,
  vNumber,
  vObject,
  vOptional,
  vOutput,
  vPicklist,
  vString,
} from '../validation'
import {
  distanceProviders,
  providerVersions,
  routingTypes,
} from './account-settings-routing'
import { messageMap, workflows } from './common'
import { labelCountSchema, labelSizeSchema, labelTypes } from './labels'

const carrierEmailTypes = ['all', ...messageMap['spot-quote']] as const

const auditThresholdTypes = ['flat', 'percent'] as const

const addressBookSettingsSchema = vObject({
  disableAutoSaveAddresses: vFallback(vBoolean(), false),
})
const bookingSettingsSchema = vObject({
  poNumberRequired: vFallback(vBoolean(), false),
  rememberSpecialInstructionsDest: vFallback(vBoolean(), true),
  rememberSpecialInstructionsOrigin: vFallback(vBoolean(), true),
  shipperNumberRequired: vFallback(vBoolean(), false),
  showBillToOnBooking: vFallback(vBoolean(), false),
  requireReasonForQuoteChoice: vFallback(vBoolean(), false),
})
const auditThresholdSettingsSchema = vObject({
  enabled: vFallback(vBoolean(), false),
  type: vPicklist(auditThresholdTypes),
  threshold: vNumber(),
})
const invoiceAutomationSettingsSchema = vObject({
  ltl: vOptional(auditThresholdSettingsSchema),
  parcel: vOptional(auditThresholdSettingsSchema),
  truckload: vOptional(auditThresholdSettingsSchema),
})
const invoiceSettingsSchema = vObject({
  autoArchiveShipment: vFallback(vBoolean(), false),
  emailDispute: vFallback(vBoolean(), true),
  automation: vOptional(invoiceAutomationSettingsSchema),
})

const notificationSettingsSchema = vObject({
  shareMissedPickup: vFallback(vBoolean(), false),
})
const ltlCatalogSettingsSchema = vObject({
  class: vFallback(vBoolean(), true),
  dims: vFallback(vBoolean(), false),
  hazardous: vFallback(vBoolean(), true),
  nmfc: vFallback(vBoolean(), false),
  package: vFallback(vBoolean(), true),
  quantity: vFallback(vBoolean(), false),
  saidToContain: vFallback(vBoolean(), true),
  stackable: vFallback(vBoolean(), false),
  weight: vFallback(vBoolean(), false),
})

const parcelCatalogSettingsSchema = vObject({
  declaredValue: vFallback(vBoolean(), false),
  dims: vFallback(vBoolean(), false),
  nonStandard: vFallback(vBoolean(), false),
  package: vFallback(vBoolean(), true),
  weight: vFallback(vBoolean(), false),
})
const productCatalogSettingsSchema = vObject({
  autoItemCreate: vFallback(vBoolean(), true),
  canQuoteCustomProduct: vFallback(vBoolean(), true),
  ltl: ltlCatalogSettingsSchema,
  parcel: parcelCatalogSettingsSchema,
})

const ltlQuoteSettingsSchema = vObject({
  hideBrokerForDirectRates: vFallback(vBoolean(), false),
})
const quotingSettingsSchema = vObject({
  ltl: vFallbackObj(ltlQuoteSettingsSchema),
  showPricingIndicator: vFallback(vBoolean(), false),
  showBidExpiration: vFallback(vBoolean(), false),
  defaultWorkflow: vFallback(vPicklist(workflows), 'ltl'),
})
const ltlShippingSettingsSchema = vObject({
  preferredLabelCount: vFallback(labelCountSchema, 1),
  preferredLabelSize: vFallback(labelSizeSchema, '4x6'),
  rememberLabelSize: vFallback(vBoolean(), false),
})
const tlShippingSettingsSchema = vObject({
  preferredLabelCount: vOptional(labelCountSchema),
  preferredLabelSize: vOptional(labelSizeSchema),
  rememberLabelSize: vFallback(vBoolean(), false),
})

const parcelShippingSettingsSchema = vObject({
  preferredLabelType: vFallback(vPicklist(labelTypes), 'pdf'),
})

const shippingSettingsSchema = vObject({
  ltl: vFallbackObj(ltlShippingSettingsSchema),
  tl: vFallback(
    vOptional(tlShippingSettingsSchema),
    createDefault(tlShippingSettingsSchema),
  ),
  parcel: vFallback(
    vOptional(parcelShippingSettingsSchema),
    createDefault(parcelShippingSettingsSchema),
  ),
})
const tagSettingsSchema = vObject({
  preventUsersCreateTags: vFallback(vBoolean(), false),
})
export const routingSettingsSchema = vObject({
  distanceProvider: vFallback(vPicklist(distanceProviders), 'pc-miler'),
  distanceProviderVersion: vFallback(vPicklist(providerVersions), 'Current'),
  routingType: vFallback(vPicklist(routingTypes), 'practical'),
  bordersOpen: vFallback(vBoolean(), true),
})
const spotQuoteSettingsSchema = vObject({
  disabledCarrierEmails: vArray(vPicklist(carrierEmailTypes)),
})
const internalSettingsSchema = vObject({
  shareQuoteData: vFallback(vBoolean(), false),
})
const truckloadSettings = vObject({
  enableMexico: vFallback(vBoolean(), false),
})
export const shipperAccountSettingsSchema = vObject({
  addressBook: vFallbackObj(addressBookSettingsSchema),
  booking: vFallbackObj(bookingSettingsSchema),
  invoicing: vFallbackObj(invoiceSettingsSchema),
  notifications: vFallbackObj(notificationSettingsSchema),
  productCatalog: vFallbackObj(productCatalogSettingsSchema),
  quoting: vFallbackObj(quotingSettingsSchema),
  shipping: vFallbackObj(shippingSettingsSchema),
  tags: vFallbackObj(tagSettingsSchema),
  routing: vFallbackObj(routingSettingsSchema),
  spotQuote: vFallbackObj(spotQuoteSettingsSchema),
  truckload: vFallbackObj(truckloadSettings),
})
export const adminAccountSettingsSchema = vObject({
  ...shipperAccountSettingsSchema.entries,
  internal: vFallbackObj(internalSettingsSchema),
})

export const provisionerAccountCreationSchema = vObject({
  email: vEmail,
  carrierId: vString(),
  password: vString(),
})

export type InvoicingSettings = vOutput<typeof invoiceSettingsSchema>
export type InvoiceAutomationSettings = vOutput<
  typeof invoiceAutomationSettingsSchema
>
export type AuditThresholdSettings = vOutput<
  typeof auditThresholdSettingsSchema
>
export type LTLCatalogSettings = vOutput<typeof ltlCatalogSettingsSchema>
export type ParcelCatalogSettings = vOutput<typeof parcelCatalogSettingsSchema>

export type BookingSettings = vOutput<typeof bookingSettingsSchema>
export type ShipperAccountSettingsModel = vOutput<
  typeof shipperAccountSettingsSchema
>
export type AccountSettings = vOutput<typeof adminAccountSettingsSchema>

export type ProvisionerAccountCreationSettings = vOutput<
  typeof provisionerAccountCreationSchema
>
