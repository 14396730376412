import dayjs from 'dayjs'

import { buildFetchOptionsWithAuth, fetchJson } from '@fv/client-core'
import { type Load } from '@fv/client-types'
import { type DTO, encodeUriAll } from '@fv/models/core'

import { apiUri, freightviewUri } from './constants'

export type MagicLinkOpts = Pick<Load, '_id' | 'status' | 'createdBy'>

export const buildViewAsLink = (email: string) => {
  return `${freightviewUri}/app/admin/change-context?username=${email}`
}

export const buildLoadMagicLink = (opts: MagicLinkOpts) => {
  const redirectUrl = `/shipper?redirectTo=${
    opts.status === 'booked' ? `details/${opts._id}` : `search?text=${opts._id}`
  }`

  return `${freightviewUri}/app/admin/change-context?username=${
    opts.createdBy.email
  }&redirect=${encodeURIComponent(redirectUrl)}`
}

export function friendlyDate(
  date?: string,
  format = 'MM/DD/YYYY hh:mm A',
  fallback = 'N/A',
) {
  if (!date) return fallback

  return dayjs.utc(date).local().format(format)
}

export const buildLogRocketSearchLink = (urlContains: string) => {
  const filter = [
    {
      id: 'BJMKN5l_3',
      type: 'timeOnPage',
      params: {
        url: {
          operator: { name: 'contains', type: 'CONTAINS', hasStrings: true },
          strings: [urlContains],
        },
        time: {
          operator: {
            name: 'for more than',
            type: 'TIME_GT',
            hasStrings: true,
            category: 'time',
            value: '1s',
          },
          strings: ['1s'],
        },
      },
    },
  ]

  return `https://app.logrocket.com/d6lkaq/freightview-production/sessions?filters=${encodeURIComponent(
    encodeURIComponent(JSON.stringify(filter)),
  )}`
}

export function getAdminLoadStatus(load: Load) {
  let status: string = load.status

  if (
    status === 'booked' &&
    (load.dispatch?.status === 'ok' ||
      load.dispatch?.status === 'not-requested' ||
      load.isLegacy)
  ) {
    status = 'dispatched'
  }

  if (
    status === 'booked' &&
    load.dispatch?.status === 'error' &&
    load.dispatch.errors?.some(e => e.type === 'validation')
  ) {
    status = 'whitelist error'
  }

  if (
    status === 'booked' &&
    load.dispatch?.status === 'pending' &&
    load.dispatch?.method === 'internal'
  ) {
    status = 'needs dispatch'
  }

  if (status === 'booked' && load.dispatch?.status === 'pending') {
    status = 'pending confirmation'
  }

  return status
}

const groupParamsByKey = (params: URLSearchParams) =>
  [...params.entries()].reduce(
    (acc, tuple) => {
      // getting the key and value from each tuple
      const [key, val] = tuple
      // eslint-disable-next-line no-prototype-builtins
      if (acc.hasOwnProperty(key)) {
        // if the current key is already an array, we'll add the value to it
        if (Array.isArray(acc[key])) {
          acc[key] = [...acc[key], val]
        } else {
          // if it's not an array, but contains a value, we'll convert it into an array
          // and add the current value to it
          acc[key] = [acc[key], val]
        }
      } else {
        // plain assignment if no special case is present
        acc[key] = val
      }

      return acc
    },
    {} as Record<string, any>,
  )

export const parseParams = <T>(search: string): T => {
  const params = new URLSearchParams(search)

  return groupParamsByKey(params) as T
}

interface FetchOptions {
  query?: Record<string, any>
  body?: Record<string, any>
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH'
}
export async function adminFetch<T>(
  path: string,
  options?: FetchOptions,
): Promise<DTO<T>> {
  const endpoint = `${apiUri}${path}`
  const response = await fetchJson(
    endpoint,
    buildFetchOptionsWithAuth({
      method: 'GET',
      ...options,
      ...(options?.body && { body: JSON.stringify(options.body) }),
    }),
  )
  if (response.ok) return response.json
  throw response.errorMessage
}

export async function adminFetchRaw(uri: string, options?: RequestInit) {
  return fetch(`${apiUri}${uri}`, {
    ...buildFetchOptionsWithAuth(),
    ...options,
  })
}

type LinkProps = {
  query: string
  startTime?: string
  endTime?: string
  project?: string
  duration?: `${string}${'M' | 'H'}`
  summaryFields?: string[]
}

export function buildGcpLogLink({
  query,
  startTime,
  endTime,
  duration,
  summaryFields,
  project,
}: LinkProps): string {
  project = project || 'freightview-1470414526790'

  const attrs = {
    query: query
      .trim()
      .split('\n')
      .map(q =>
        q
          .replace(/\s+/g, '')
          .replace(/=~"(.+)"/g, (m, g1) => `=~"${encodeUriAll(g1)}"`),
      )
      .join('%0A'),

    ...(summaryFields?.length && {
      summaryFields: summaryFields
        .map(s => encodeUriAll(s.replace(/\./g, '/')))
        .join(','),
    }),
    ...(startTime && {
      startTime: dayjs.utc(startTime).toISOString(),
    }),
    ...(endTime && {
      endTime: dayjs.utc(endTime).toISOString(),
    }),
    ...(endTime && {
      endTime: dayjs.utc(endTime).toISOString(),
    }),
    ...(duration && {
      duration: `PT${duration}`,
    }),
  }

  console.log(attrs)

  const attrsStr = Object.entries(attrs)
    .map(([key, value]) => `${key}=${value}`)
    .join(';')

  return `https://console.cloud.google.com/logs/query;${attrsStr}?project=${project}`
}
