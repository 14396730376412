import { ObjectId } from '@freightview/object-id'

import {
  vISODateTime,
  vObject,
  vOutput,
  vPicklist,
  vPipe,
  vString,
} from '../validation'

export const systemNotificationTypes = [
  'warning',
  'holiday',
  'release',
  'info',
  'default',
  'success',
] as const

export type SystemNotificationType = (typeof systemNotificationTypes)[number]

export interface SystemNotification {
  _id: ObjectId
  expiration: Date
  type: SystemNotificationType
  text: string
}
export type SystemGeneratedNotification = Omit<SystemNotification, '_id'> & {
  _id: string
}

export const upsertSystemNotificationSchema = vObject({
  text: vString(),
  expiration: vPipe(vString(), vISODateTime()),
  type: vPicklist(systemNotificationTypes),
})
export type UpsertSystemNotificationDTO = vOutput<
  typeof upsertSystemNotificationSchema
>
