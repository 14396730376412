import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

import { type Page } from '@fv/client-core'
import { type CarrierDTO, type CarrierStatsDTO } from '@fv/models'
import { adminFetch } from '@/utils'

export const carrierQueryKeys = {
  all: ['carriers'],
  id: (id?: string) => [...carrierQueryKeys.all, ...(id ? [id] : [])],
  search: (search: string, includeRemoved = false) => [
    ...carrierQueryKeys.all,
    'search',
    search,
    includeRemoved.toString(),
  ],
  users: (id: string) => [...carrierQueryKeys.all, 'users', id],
  stats: (id: string) => [...carrierQueryKeys.all, 'stats', id],
}

export function useSearchCarriers(search: string, includeRemoved = false) {
  return useInfiniteQuery(
    carrierQueryKeys.search(search, includeRemoved),
    ({ pageParam = '' }) => {
      return adminFetch<Page<CarrierDTO>>(`/carriers`, {
        query: { search, continuationToken: pageParam, includeRemoved },
      })
    },
    {
      getNextPageParam: p => p.continuationToken,
      staleTime: Infinity,
    },
  )
}

export function useFetchCarrier(id: string) {
  return useQuery(
    carrierQueryKeys.id(id),
    () => adminFetch<CarrierDTO & { currentPro?: number }>(`/carriers/${id}`),
    {
      staleTime: Infinity,
      enabled: !!id,
    },
  )
}

export function useFetchCarrierStats(id: string) {
  return useQuery(
    carrierQueryKeys.stats(id),
    () => adminFetch<CarrierStatsDTO>(`/carriers/${id}/stats`),
    {
      staleTime: Infinity,
    },
  )
}
