import { useState } from 'react'
import { useToggle } from 'usehooks-ts'

import { AdminSection } from '@/components/shared/AdminSection'

import { AdminSliderPanel } from '../../components/AdminSliderPanel'
import { AdminButton } from '../../components/shared/AdminButton'
import { useDeleteNotification } from './mutations'
import { NotificationForm } from './NotificationForm'
import { NotificationList } from './NotificationList'
import { type NotificationFormModel } from './types'

export const NotificationsCard = () => {
  const [sliderOpen, toggleSliderOpen] = useToggle()
  const [editModel, setEditModel] = useState<NotificationFormModel>()
  const deleteNotificaiton = useDeleteNotification()

  const handleEdit = (note: NotificationFormModel) => {
    setEditModel(note)
    toggleSliderOpen()
  }
  const handleRemove = async (note: NotificationFormModel) => {
    if (!window.confirm('Are you sure you want to delete this notification?')) {
      return
    }
    await deleteNotificaiton.mutateAsync(note._id)
  }
  const handleClose = () => {
    setEditModel(undefined)
    toggleSliderOpen()
  }
  return (
    <AdminSection
      title="Notifications"
      className="min-h-40"
      headerActions={
        <AdminButton
          className="btn-sm ml-auto"
          icon="plus"
          onClick={toggleSliderOpen}
        >
          Add notification
        </AdminButton>
      }
    >
      <NotificationList onEdit={handleEdit} onRemove={handleRemove} />
      <AdminSliderPanel
        isOpen={sliderOpen}
        closePanel={handleClose}
        title="Create notification"
      >
        {sliderOpen && ( // force remount
          <NotificationForm initialValue={editModel} onClose={handleClose} />
        )}
      </AdminSliderPanel>
    </AdminSection>
  )
}
