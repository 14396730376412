import clsx from 'clsx'
import { type PropsWithChildren, type ReactNode } from 'react'

import { type PropsWithClassName } from '@fv/client-types'

type Props = {
  headerActions?: ReactNode
  headerClassName?: string
  title?: ReactNode
}
export const AdminSection = ({
  children,
  headerActions,
  headerClassName,
  title,
  className,
}: PropsWithClassName<PropsWithChildren<Props>>) => {
  return (
    <div className={clsx('card border-base-300 border shadow-lg', className)}>
      {(title || headerActions) && (
        <div
          className={clsx(
            'border-base-300 flex items-center gap-x-4 border-b p-2 pb-2 text-lg',
            headerClassName,
            {
              'bg-neutral/30': !headerClassName?.includes('bg-'),
            },
          )}
        >
          {title && typeof title === 'string' ? <div>{title}</div> : title}
          {headerActions}
        </div>
      )}
      <div className="p-2">{children}</div>
    </div>
  )
}
