import clsx from 'clsx'

import { type PropsWithClassName } from '@fv/client-types'
import { type CarrierStatus } from '@fv/models'

type StatusProps = PropsWithClassName<{ status: CarrierStatus }>
export const CarrierStatusBadge = ({ status, className }: StatusProps) => (
  <span
    className={clsx('badge badge-outline', className, {
      'badge-ghost': status === 'hidden',
      'badge-warning': status === 'pending',
      'badge-success': status === 'active',
      'badge-error': status === 'removed',
    })}
  >
    {status}
  </span>
)
