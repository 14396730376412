import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { ValidatedForm } from '@fv/client-components'
import { type AdminCarrierUser, type CarrierDTO } from '@fv/models'
import { type DTO } from '@fv/models/core'
import { AdminSliderPanel } from '@/components/AdminSliderPanel'
import { AdminButton } from '@/components/shared/AdminButton'
import { AdminFormActions } from '@/components/shared/AdminFormActions'
import { AdminFormGroup } from '@/components/shared/AdminFormGroup'
import { usersQueryKeys } from '@/hooks/useFetchUsers'

import { CarrierStatusBadge } from '../carriers/components/CarrierBadges'
import { CarrierTypeAhead } from '../carriers/components/CarrierTypeAhead'
import { useFetchCarrier } from '../carriers/queries'
import { NO_EXPORT_ATTR } from '../reports/helpers'
import { useChangeCarrier } from './mutations'

type Props = {
  user: DTO<AdminCarrierUser>
}

export const ChangeCarrierSliderButton = ({ user }: Props) => {
  const queryClient = useQueryClient()
  const [localCarrier, setLocalCarrier] = useState<CarrierDTO>()
  const [isOpen, setIsOpen] = useState(false)
  const changeCarrier = useChangeCarrier(user._id)
  const currentCarrier = useFetchCarrier(user.carrier?._id)

  const handleSubmit = async () => {
    await changeCarrier.mutateAsync(localCarrier._id)
    queryClient.invalidateQueries(usersQueryKeys.all)
    setIsOpen(false)
  }

  useEffect(() => {
    setLocalCarrier(null)
  }, [isOpen])

  return (
    <>
      <AdminButton
        className="btn-xs btn-accent"
        icon="exchange"
        onClick={() => setIsOpen(true)}
        {...{ [NO_EXPORT_ATTR]: true }}
      >
        Change carrier
      </AdminButton>
      <AdminSliderPanel
        isOpen={isOpen}
        closePanel={() => setIsOpen(false)}
        title={`Change carrier for ${user?.email}`}
      >
        {!!user && (
          <ValidatedForm
            onValidSubmit={handleSubmit}
            className="flex flex-col gap-4"
          >
            <AdminFormGroup label="Current carrier">
              <div className="flex flex-col leading-tight">
                <div>
                  {currentCarrier.data?.name}{' '}
                  <CarrierStatusBadge
                    status={currentCarrier.data?.status}
                    className="badge-xs"
                  />
                </div>

                <small>{currentCarrier.data?._id}</small>
              </div>
            </AdminFormGroup>

            <AdminFormGroup label="New carrier">
              <CarrierTypeAhead
                carrier={localCarrier}
                name="new-carrier"
                onCarrierChange={setLocalCarrier}
              />
            </AdminFormGroup>
            <div>
              <AdminFormActions onCancel={() => setIsOpen(false)} />
            </div>
          </ValidatedForm>
        )}
      </AdminSliderPanel>
    </>
  )
}
