import { type PropsWithChildren } from 'react'

export const AdminLoading = () => {
  return (
    <div className="flex items-center justify-center p-4">
      <span className="loading loading-spinner loading-md" />
      <span className="pl-4">Loading</span>
    </div>
  )
}

type Props = PropsWithChildren<{
  query: {
    isLoading?: boolean
  }
}>

export const QuerySuspense = ({ query, children }: Props) =>
  query.isLoading ? <AdminLoading /> : <>{children}</>
