import {
  vArray,
  vBoolean,
  vCoerceArray,
  vDate,
  vFallback,
  vInt,
  vMaxValue,
  vMinValue,
  vObject,
  vOptional,
  vOptionalDef,
  vOutput,
  vPicklist,
  vPipe,
  vString,
  vStringToNumber,
} from '../validation/index'
import { auditStatuses } from './audit'
import {
  modes,
  pricingMethods,
  pricingTypes,
  shipmentDirections,
} from './common'
import { loadDocTypes } from './load-document'
import { SortDir, sortDirSchema } from './sort'

export const shipmentStatuses = [
  'pending',
  'awarded',
  'confirmed',
  'picked-up',
  'delivered',
  'canceled',
] as const
export type ShipmentStatus = (typeof shipmentStatuses)[number]

export const shipmentSortTypes = [
  'pickupDate',
  'messageDate',
  'deliveryDate',
  'status',
  'carrier',
] as const
export type ShipmentSortType = (typeof shipmentSortTypes)[number]

export const recentShipmentFilters = [
  'recent',
  'saved',
  'booking-error',
] as const

const sortBySchema = vPicklist(shipmentSortTypes)
const sortSchema = vObject({
  sortBy: sortBySchema,
  sortDirection: vFallback(sortDirSchema, -1 as SortDir),
})
export type SortBase = vOutput<typeof sortSchema>
const statusSchema = vArray(vPicklist(shipmentStatuses))
export const loadSearchBaseSchema = vObject({
  search: vOptional(vString()),
})
export const loadFilterSchema = vObject({
  ...loadSearchBaseSchema.entries,
  bolNumber: vOptional(vString()),
  bookedBy: vOptional(vArray(vString())),
  bookedDate: vOptional(vString()),
  carrier: vOptional(vArray(vString())),
  direction: vOptional(vArray(vPicklist(shipmentDirections))),
  isArchived: vOptional(vBoolean()),
  isFreightCollect: vOptional(vBoolean()),
  isInterline: vOptional(vBoolean()),
  isSpot: vOptional(vBoolean()),
  location: vOptional(vArray(vString())),
  mode: vOptional(vCoerceArray(vPicklist(modes))),
  pickupConfirmationNumber: vOptional(vString()),
  pickupDate: vOptional(vString()),
  pricingMethod: vOptional(vArray(vPicklist(pricingMethods))),
  pricingType: vOptional(vArray(vPicklist(pricingTypes))),
  quotedBy: vOptional(vArray(vString())),
  referenceNumber: vOptional(vString()),
  status: vOptional(statusSchema),
  tag: vOptional(vArray(vString())),
  trackingNumber: vOptional(vString()),
})
export type LoadFilterBase = vOutput<typeof loadFilterSchema>

const skipLimitSchema = vObject({
  limit: vFallback(vPipe(vStringToNumber(), vInt(), vMaxValue(20)), 20),
  skip: vFallback(vPipe(vStringToNumber(), vInt(), vMinValue(0)), 0),
})

const inProcessStatuses = ['awarded', 'pending'] as const
export const inProcessFilterSchema = vObject({
  ...sortSchema.entries,
  ...skipLimitSchema.entries,
  ...loadFilterSchema.entries,
  isLiveLoad: vOptional(vBoolean()),
  recentType: vOptional(vPicklist(recentShipmentFilters)),
  sortBy: vFallback(sortBySchema, 'pickupDate'),
  status: vOptionalDef(statusSchema, [...inProcessStatuses]),
})
export type InProcessLookupDTO = vOutput<typeof inProcessFilterSchema>

export const readyCompletedStatuses = [
  'confirmed',
  'picked-up',
  'delivered',
  'canceled',
] as const
export const readyCompletedFilterSchema = vObject({
  ...sortSchema.entries,
  ...skipLimitSchema.entries,
  ...loadFilterSchema.entries,
  auditStatus: vOptional(vCoerceArray(vPicklist(auditStatuses))),
  deliveryDate: vOptional(vString()),
  hasInvoice: vOptional(vBoolean()),
  sortBy: vFallback(sortBySchema, 'pickupDate'),
  status: vOptionalDef(statusSchema, [...readyCompletedStatuses]),
  missingDocs: vOptional(vCoerceArray(vPicklist(loadDocTypes))),
  hasDocs: vOptional(vCoerceArray(vPicklist(loadDocTypes))),
})
export type ReadyCompletedLookupDTO = vOutput<typeof readyCompletedFilterSchema>

export const externalApiFilterSchema = vObject({
  ...loadFilterSchema.entries,
  continuationToken: vOptional(vString()),
  deliveryDateStart: vOptional(vDate()),
  deliveryDateEnd: vOptional(vDate()),
  bookedDateStart: vOptional(vDate()),
  bookedDateEnd: vOptional(vDate()),
  pickupDateStart: vOptional(vDate()),
  pickupDateEnd: vOptional(vDate()),
  auditStatus: vOptional(vPicklist(auditStatuses)),
  status: vOptionalDef(vCoerceArray(vPicklist(shipmentStatuses)), [
    ...shipmentStatuses,
  ]),
})
export type ExternalSearchReqDTO = vOutput<typeof externalApiFilterSchema>
