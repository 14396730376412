import { useQuery } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { showApiError } from '@fv/client-core'
import { type AdminCarrierUser } from '@fv/models'

import { adminFetch } from '../utils'

export const usersQueryKeys = {
  all: ['users'],
  user: (id: string) => [...usersQueryKeys.all, id],
  search: (search: string, limit: number) => [
    ...usersQueryKeys.all,
    search,
    limit,
  ],
}

export function useFetchUser(id?: string) {
  return useQuery<{
    profile: { firstName: string; lastName: string }
    email: string
    _id: string
  }>(usersQueryKeys.user(id), () => adminFetch(`/users/${id}`), {
    enabled: !!id,
    onError(e: any) {
      toast.error(e.message || 'Error fetching user')
    },
  })
}

export function useFetchUsers(search: string, limit: number) {
  return useQuery(
    usersQueryKeys.search(search, limit),
    () =>
      adminFetch<AdminCarrierUser[]>('/users', {
        query: { search, limit },
      }),
    {
      refetchOnWindowFocus: false,
      onError: e => showApiError('Error searching users', e),
    },
  )
}

type GenericUser = {
  firstName?: string
  lastName?: string
  email: string
  _id: string
}
export function useFetchGenericUser(id: string | undefined) {
  return useQuery(
    ['generic-user', id],
    () => adminFetch<GenericUser>(`/users/generic/${id}`),
    {
      enabled: !!id,
    },
  )
}
