export const modes = [
  'truckload',
  'parcel',
  'ltl',
  'intermodal',
  'consolidated',
  'air',
  'ocean',
  'bulk',
] as const
export type Mode = (typeof modes)[number]

export const weightUOM = ['lbs', 'kgs'] as const
export type WeightUOM = (typeof weightUOM)[number]

export const currency = [
  {
    key: 'usd',
    name: 'USD',
  },
  {
    key: 'cad',
    name: 'CAD',
  },
] as const
export type Currency = (typeof currency)[number]['key']

export const temperatureUOM = [
  {
    key: 'f',
    name: `${String.fromCharCode(176)}F`,
  },
  {
    key: 'c',
    name: `${String.fromCharCode(176)}C`,
  },
] as const
export type TemperatureUOM = (typeof temperatureUOM)[number]['key']

export const refNumTypes = [
  'load-num',
  'seal-num',
  'trailer-num',
  'other',
] as const
export const itemRefNumTypes = ['tracking-num', 'other'] as const
export const locationRefNumTypes = ['other'] as const
export const locationTypes = ['origin', 'destination', 'stop'] as const
export const stopTypes = ['pickup', 'delivery', 'both'] as const
export const stopDateTypes = ['on', 'by', 'between'] as const
export type RefNumType = (typeof refNumTypes)[number]
export type LocationRefNumType = (typeof locationRefNumTypes)[number]
export type ItemRefNumType = (typeof itemRefNumTypes)[number]
export type LocationType = (typeof locationTypes)[number]
export type StopType = (typeof stopTypes)[number]
export type StopDateType = (typeof stopDateTypes)[number]

export const applicationTypes = [
  'carrier-web',
  'admin-web',
  'freightview',
  'shipper-web',
] as const
export type ApplicationType = (typeof applicationTypes)[number]

export const dimensionsUOM = ['in', 'cm', 'ft'] as const
export type DimensionUOM = (typeof dimensionsUOM)[number]

export const locales = [
  {
    key: 'en-us',
    name: 'en-US',
  },
  {
    key: 'en-mx',
    name: 'en-MX',
  },
  {
    key: 'en-ca',
    name: 'en-CA',
  },
] as const
export type Locale = (typeof locales)[number]['key']

export const pricingMethods = ['contracted', 'capacity'] as const
export type PricingMethod = (typeof pricingMethods)[number]

export const pricingTypes = [
  'pallet',
  'dimensional',
  'spot',
  'tariff',
  'density',
] as const
export type PricingType = (typeof pricingTypes)[number]

export const productTypes = [
  'fuelAndDistance',
  'parcel',
  'ltl',
  'truckLoad',
  'mixedPallets',
  'automatedAuditing',
  'subscriptionsV2',
] as const
export type ProductType = (typeof productTypes)[number]

export const paymentTerms = [
  'outbound-prepaid',
  'outbound-collect',
  'inbound-collect',
  'third-party-prepaid',
  'third-party-collect',
] as const
export type PaymentTerms = (typeof paymentTerms)[number]

export const tokenTypes = ['session', 'shipment', 'register'] as const
export type TokenType = (typeof tokenTypes)[number]

export const accountRoleTypes = [
  'admins',
  'beta',
  'demo',
  'test',
  'provisioner',
] as const
export type AccountRoleType = (typeof accountRoleTypes)[number]

export const userPermissionTypes = ['audit', 'book', 'manage', 'quote'] as const
export type UserPermissionType = (typeof userPermissionTypes)[number]

export const messageDeliveryStatuses = [
  'na',
  'queued',
  'sent',
  'dropped',
  'bounced',
  'deferred',
  'delivered',
  'failed',
  'opened',
  'processed',
  'viewed', // Adding this for if they view a message before receiving their digest. YAGNI???
] as const
export type MessageDeliveryStatus = (typeof messageDeliveryStatuses)[number]

export const quoteStatuses = [
  'active',
  'awarded',
  'retracted',
  'error',
] as const
export type QuoteStatus = (typeof quoteStatuses)[number]

export const quoteMethodTypes = [
  'api', // The rate came from Hydra or some other rating microservice
  'upload', // The rate was uploaded by the shipper (loaded rates)
  'email', // Not used / legacy
  'manual', // Shipper or carrier entered the rate manually via shipper/carrier portal
] as const
export type QuoteMethodType = (typeof quoteMethodTypes)[number]

export const quoteSourceTypes = ['shipper', 'carrier'] as const
export type QuoteSourceType = (typeof quoteSourceTypes)[number]

export const subApps = ['admin', 'carrier', 'v2.0', 'shipper'] as const
export type SubApp = (typeof subApps)[number]

export const distanceUOMs = ['mi', 'km'] as const
export type DistanceUOM = (typeof distanceUOMs)[number]

export const workflows = ['parcel', 'ltl', 'truckload'] as const
export type Workflow = (typeof workflows)[number]

export const hazardClasses = [
  { key: '1.1A', isValidSubClass: false }, // EXPLOSIVES - MASS EXPLOSIVE HAZARD
  { key: '1.1B', isValidSubClass: false }, // EXPLOSIVES - MASS EXPLOSIVE HAZARD
  { key: '1.1C', isValidSubClass: false }, // EXPLOSIVES - MASS EXPLOSIVE HAZARD
  { key: '1.1D', isValidSubClass: false }, // EXPLOSIVES - MASS EXPLOSIVE HAZARD
  { key: '1.1E', isValidSubClass: false }, // EXPLOSIVES - MASS EXPLOSIVE HAZARD
  { key: '1.1F', isValidSubClass: false }, // EXPLOSIVES - MASS EXPLOSIVE HAZARD
  { key: '1.1G', isValidSubClass: false }, // EXPLOSIVES - MASS EXPLOSIVE HAZARD
  { key: '1.1J', isValidSubClass: false }, // EXPLOSIVES - MASS EXPLOSIVE HAZARD
  { key: '1.1L', isValidSubClass: false }, // EXPLOSIVES - MASS EXPLOSIVE HAZARD
  { key: '1.2B', isValidSubClass: false }, // EXPLOSIVES - PROJECTION HAZARD
  { key: '1.2C', isValidSubClass: false }, // EXPLOSIVES - PROJECTION HAZARD
  { key: '1.2D', isValidSubClass: false }, // EXPLOSIVES - PROJECTION HAZARD
  { key: '1.2E', isValidSubClass: false }, // EXPLOSIVES - PROJECTION HAZARD
  { key: '1.2F', isValidSubClass: false }, // EXPLOSIVES - PROJECTION HAZARD
  { key: '1.2G', isValidSubClass: false }, // EXPLOSIVES - PROJECTION HAZARD
  { key: '1.2H', isValidSubClass: false }, // EXPLOSIVES - PROJECTION HAZARD
  { key: '1.2J', isValidSubClass: false }, // EXPLOSIVES - PROJECTION HAZARD
  { key: '1.2K', isValidSubClass: false }, // EXPLOSIVES - PROJECTION HAZARD
  { key: '1.2L', isValidSubClass: false }, // EXPLOSIVES - PROJECTION HAZARD
  { key: '1.3C', isValidSubClass: false }, // EXPLOSIVES - MASS FIRE HAZARD
  { key: '1.3F', isValidSubClass: false }, // EXPLOSIVES - MASS FIRE HAZARD
  { key: '1.3G', isValidSubClass: false }, // EXPLOSIVES - MASS FIRE HAZARD
  { key: '1.3H', isValidSubClass: false }, // EXPLOSIVES - MASS FIRE HAZARD
  { key: '1.3J', isValidSubClass: false }, // EXPLOSIVES - MASS FIRE HAZARD
  { key: '1.3K', isValidSubClass: false }, // EXPLOSIVES - MASS FIRE HAZARD
  { key: '1.3L', isValidSubClass: false }, // EXPLOSIVES - MASS FIRE HAZARD
  { key: '1.4B', isValidSubClass: false }, // EXPLOSIVES - MINOR EXPLOSION HAZARD
  { key: '1.4C', isValidSubClass: false }, // EXPLOSIVES - MINOR EXPLOSION HAZARD
  { key: '1.4D', isValidSubClass: false }, // EXPLOSIVES - MINOR EXPLOSION HAZARD
  { key: '1.4E', isValidSubClass: false }, // EXPLOSIVES - MINOR EXPLOSION HAZARD
  { key: '1.4F', isValidSubClass: false }, // EXPLOSIVES - MINOR EXPLOSION HAZARD
  { key: '1.4G', isValidSubClass: false }, // EXPLOSIVES - MINOR EXPLOSION HAZARD
  { key: '1.4S', isValidSubClass: false }, // EXPLOSIVES - MINOR EXPLOSION HAZARD
  { key: '1.5D', isValidSubClass: false }, // EXPLOSIVES - VERY INSENSITIVE EXPLOSIVES
  { key: '1.6N', isValidSubClass: false }, // EXPLOSIVES - EXTREMELY INSENSITIVE EXPLOSIVES
  { key: '2.1', isValidSubClass: true }, // GASES - FLAMMABLE
  { key: '2.2', isValidSubClass: true }, // GASES - NON-FLAMMABLE
  { key: '2.3', isValidSubClass: true }, // GASES - POISONOUS OR TOXIC
  { key: '3', isValidSubClass: true }, // FLAMMABLE LIQUID
  { key: '4.1', isValidSubClass: true }, // FLAMMABLE SOLID
  { key: '4.2', isValidSubClass: true }, // FLAMMABLE SOLID - SPONTANEOUSLY COMBUSTIBLE MATERIAL
  { key: '4.3', isValidSubClass: true }, // FLAMMABLE SOLID - DANGEROUS WHEN WET
  { key: '5.1', isValidSubClass: true }, // OXIDIZER
  { key: '5.2', isValidSubClass: true }, // ORGANIC PEROXIDE
  { key: '6.1', isValidSubClass: true }, // POISONOUS (TOXIC) MATERIAL
  { key: '6.2', isValidSubClass: true }, // INFECTIOUS SUBSTANCE
  { key: '7', isValidSubClass: true }, // RADIOACTIVE MATERIAL
  { key: '8', isValidSubClass: true }, // CORROSIVES
  { key: '9', isValidSubClass: true }, // MISCELLANEOUS DANGEROUS GOODS
  { key: '9.2', isValidSubClass: true }, // MISCELLANEOUS DANGEROUS GOODS - ENVIRONMENTALLY HAZARDOUS SUBSTANCE
] as const

export type HazardClass = (typeof hazardClasses)[number]['key']

export const hazardSubClasses = [
  '2.1',
  '2.2',
  '2.3',
  '3',
  '4.1',
  '4.2',
  '4.3',
  '5.1',
  '5.2',
  '6.1',
  '6.2',
  '7',
  '8',
  '9',
  '9.2',
] as const satisfies HazardClass[]

export type HazardSubClass = (typeof hazardSubClasses)[number]

export const freightClasses = [
  50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300,
  400, 500,
] as const

export type FreightClass = (typeof freightClasses)[number]

export const messageMap = {
  'spot-quote': [
    'request',
    'message',
    'decline',
    'quote',
    'award',
    'not-awarded',
    'confirm',
    'upload',
    'cancel',
    'change',
    'tender',
    'retract',
    'retract-tender',
    'share',
    'tracking-update',
    'reject',
  ] as const,
  audit: ['dispute'] as const,
  share: ['public', 'shipper'] as const,
} as const

export const messageTypes = Object.keys(messageMap) as MessageType[]
export type MessageType = keyof typeof messageMap
export const messageSubTypes = Object.values(messageMap).flatMap(v => v)
export type MessageSubType = (typeof messageSubTypes)[number]

const addressTypes = ['billing', 'shipping'] as const
export type AddressType = (typeof addressTypes)[number]
export const shipmentDirections = [
  'inbound',
  'outbound',
  'third-party',
] as const
export type ShipmentDirection = (typeof shipmentDirections)[number]
