import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { type PropsWithChildren } from 'react'

import { type PropsWithClassName } from '@fv/client-types'
import { usersQueryKeys } from '@/hooks/useFetchUsers'

import { carrierUri } from '../../constants'
import { useViewAsCarrier } from '../../hooks/useViewAsCarrier'
import { NO_EXPORT_ATTR } from '../reports/helpers'

export type ViewAsProps = {
  userId: string
  redirect?: string
}

export const ViewAsLink = (
  props: PropsWithClassName<PropsWithChildren<ViewAsProps>>,
) => {
  const viewAsCarrier = useViewAsCarrier()
  const queryClient = useQueryClient()

  return (
    <a
      {...{ [NO_EXPORT_ATTR]: true }}
      className={clsx(`link link-primary`, props.className)}
      href="#"
      onClick={e => {
        e.preventDefault()
        viewAsCarrier.mutateAsync(props.userId).then(({ token }) => {
          let uri = `${carrierUri}/login/${token}`

          if (props.redirect) {
            uri += `?redirect=${encodeURIComponent(props.redirect)}`
          }

          queryClient.invalidateQueries(usersQueryKeys.all)

          window.open(uri, 'view-as-carrier-web')
        })
      }}
    >
      {props.children || 'View as'}
    </a>
  )
}
