import debounce from 'lodash/debounce'
import { useCallback, useState } from 'react'

import { QuerySuspense } from '@/components/shared/AdminLoading'
import { AdminTextField } from '@/components/shared/AdminTextField'

import { AdminSection } from '../../components/shared/AdminSection'
import { useFetchUsers } from '../../hooks/useFetchUsers'
import { CarrierUsersTable } from './CarrierUsersTable'

export const Search = () => {
  const [search, setSearch] = useState('')
  const searchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value.trim())
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce(searchChange, 500), [])
  const usersQuery = useFetchUsers(search, 20)

  return (
    <>
      <AdminSection
        title="Carrier users"
        className="min-h-"
        headerActions={
          <div className="join ml-auto w-80">
            <AdminTextField
              maxLength={200}
              name="search"
              onChange={debouncedSearch}
              placeholder="Search..."
            />
          </div>
        }
      >
        <QuerySuspense query={usersQuery}>
          <CarrierUsersTable users={usersQuery.data} showCarrierCol />
        </QuerySuspense>
      </AdminSection>
    </>
  )
}
